import { Layout } from "../components/layout";
import { Container } from "../components/container";

export default function NotFoundPage() {
  return (
    <Layout hideBreadcrumbs={true}>
      <Container>
        <div className="mx-auto mb-12 mt-12 border-2 border-light-3 p-6 sm:w-[500px] md:w-[670px] lg:mb-24 lg:mt-20 lg:w-[690px] lg:p-8">
          <h1 className="text-24 font-semibold lg:text-40">Seite nicht gefunden</h1>
          <div className="mt-6">
            <div className="font-semibold">
              Bitte entschuldigen Sie. Die Seite wurde verschoben oder ist nicht länger verfügbar.
            </div>
            <div className="mt-3">Bitte nutzen Sie eine der folgenden Möglichkeiten:</div>
            <ul className="mt-2 list-disc pl-4">
              <li>Prüfen Sie die Adresse der Webseite falls Sie diese von Hand eingegeben haben</li>
              <li>
                Versuchen Sie die Seite direkt über die Navigation aufzurufen und nicht über ein
                gespeichertes Lesezeichen (Bookmark). Falls die Seite verschoben wurde,
                aktualisieren Sie bitte ihr Lesezeichen.
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
